import type { User } from 'firebase/auth';

// eslint-disable-next-line complexity
export default defineNuxtRouteMiddleware(async () => {
  const user: User | null = await getCurrentUser();
  const tokens = await user?.getIdTokenResult();

  // redirect the user to the login page
  if (!tokens?.claims.leader || !tokens.claims.admin) {
    return navigateTo({
      path: '/acesso-negado',
      replace: true,
    });
  }
});